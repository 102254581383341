import React, { Component } from 'react';
import './PairPictures.css';
import Picture from './Picture';
import { Line} from 'rc-progress';
import Parent from './Parent';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/firestore';
import { DB_CONFIG } from '../config/config';


class PairPictures extends Component {

    constructor(props) {
        super(props);
        this.app = firebase.initializeApp(DB_CONFIG);
        this.database = this.app.database();
        this.firestore = this.app.firestore()

        // Bind the this context to the handler function
        this.handler = this.handler.bind(this);

        this.state = {
            data: [
                {
                    id: "prva",
                    name: "Prva",
                },
                {
                    id: "druga",
                    name: "Druga"
                }
            ],
            percent: 13,
        };
    }


    handler() {
        this.setState({
            percent: this.state.percent + 1

        });
        let dbCon = this.database.ref('/messages');
        dbCon.push({
            message: 'mojmessage'
        });
        let fsCon = this.firestore.collection("messages")
        fsCon.add({
            fullname: 'Pero',
            email: 'azra@am.com'
        });
    }

    render() {
        var pictures = this.state.data.map((slika) =>
            <Picture data={slika} key={slika.id} action={this.handler} />
        );

        return (
            <div className="pictures-container">
                <ul className="pictures">{pictures}</ul>
                <div className="pictures-slider">
                    <Line percent={this.state.percent} strokeWidth="4" strokeColor="#FFD700"  />
                </div>
            </div>
        );
    }
}

export default PairPictures;
