import React, { Component } from 'react';
import './Picture.css';
import 'firebase/storage'
import firebase from "firebase";
import {DB_CONFIG} from "../config/config";

class Picture extends Component {
    constructor(props) {
        super(props)
        this.state = {
            clicks: 0,
            imageurl : ''
        };
        this.onCowClick = this.onCowClick.bind(this)
        this.storage = firebase.storage().ref('images')
        this.getImage('p00005')

    }
    onCowClick () {
        this.setState({
            clicks: this.state.clicks + 1
        });
        this.props.action()
    };
    getImage (image) {
        const min = 1;
        const max = 20;
        const rand = Math.floor(Math.random() * max) + min ;
        const pic = 'p0000'.concat(rand)
        this.storage.child(`${rand}.jpg`).getDownloadURL().then((url) => {
            this.setState({
                imageurl: url
            });
        })
    }


    render() {

            var {
            id,
            name
        } = this.props.data;
        return (
            <div>
                <div>Clicks: {this.state.clicks}</div>
                <img
                    src= {this.state.imageurl}
                    onClick={this.onCowClick}
                    className="picture"
                    alt={this.name}
                />
            </div>
        );
    }
}

export default Picture;
