import React, { Component } from 'react';
import {Button} from 'react-bootstrap'

class Child extends Component {
    render() {
        return (
            <div>
                {/* The button will execute the handler function set by the parent component */}
                <Button onClick={this.props.action} />
                
            </div>
        )
    }
}

export default Child;