import React, { Component } from 'react';
import './App.css';
import PairPictures from './components/PairPictures.js';

class App extends Component {
    render() {
        return (
            <div className="App-header">
                <h2>Picturenality</h2>
                <PairPictures />
            </div>
        );
    }
}

export default App;
	