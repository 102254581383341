import React, { Component } from 'react';
import Child from './Child';

class Parent extends Component {
    constructor(props) {
        super(props)

        // Bind the this context to the handler function
        this.handler = this.handler.bind(this);

        // Set some state
        this.state = {
            messageShown: 'false'
        };
    }

    // This method will be sent to the child component
    handler() {
            if (this.state.messageShown === 'false') {
                this.setState({
                    messageShown: 'true'           
                })
            } else {
                this.setState({
                    messageShown: 'false'             
                })
            };
    }

    // Render the child component and set the action property with the handler as value
    render() {
        return (
            <div>
                <Child action={this.handler} />
                <div>{this.state.messageShown}</div>
            </div>
        )
    }
}

export default Parent;